let context;
let voice1Oscillator, voice2Oscillator, lfoOscillator, filter;
let analyser, dataArray, canvas, canvasCtx;
let synthRunning = false;

document.getElementById("startSynth").addEventListener("click", () => {
    // Initialize AudioContext if it doesn't exist
    if (!context) {
        context = new (window.AudioContext || window.webkitAudioContext)();
        setupVisualizer();
    }

    // Resume AudioContext if it is suspended (this is usually the issue with first clicks)
    if (context.state === 'suspended') {
        context.resume().then(() => {
            // Start or stop the synth after resuming
            if (!synthRunning) {
                startSynth();
            } else {
                stopSynth();
            }
        });
    } else {
        // Start or stop the synth directly if not suspended
        if (!synthRunning) {
            startSynth();
        } else {
            stopSynth();
        }
    }
});

function startSynth() {
    const baseFrequency = parseFloat(document.getElementById('frequency').value);
    const distance = parseFloat(document.getElementById('distance').value);
    const lfoFreq = parseFloat(document.getElementById('lfo').value);
    const lfoAmount = parseFloat(document.getElementById('filterFreq').value); // Repurposed as LFO amount

    // Create the filter before starting the oscillators
    filter = createFilter();

    // Create voice oscillators with base frequency and distance
    voice1Oscillator = createOscillator(baseFrequency, 0.5);
    voice2Oscillator = createOscillator(baseFrequency + distance, 0.5);

    // Create LFO to modulate both voices
    lfoOscillator = createLFO(lfoFreq, lfoAmount);

    // Connect oscillators to filter, then to analyser, and finally to destination
    voice1Oscillator.gainNode.connect(filter);
    voice2Oscillator.gainNode.connect(filter);
    filter.connect(analyser);
    analyser.connect(context.destination);

    // Connect LFO to oscillators, scaling by LFO amount
    connectLFOToOscillators(lfoOscillator, voice1Oscillator);
    connectLFOToOscillators(lfoOscillator, voice2Oscillator);

    // Start oscillators
    voice1Oscillator.oscillator.start();
    voice2Oscillator.oscillator.start();
    lfoOscillator.oscillator.start();

    document.getElementById("startSynth").textContent = "Stop Synth";
    synthRunning = true;
    visualize(); // Start visualizing
}

function stopSynth() {
    if (voice1Oscillator && voice2Oscillator && lfoOscillator) {
        voice1Oscillator.oscillator.stop();
        voice2Oscillator.oscillator.stop();
        lfoOscillator.oscillator.stop();
    }

    document.getElementById("startSynth").textContent = "Start Synth";
    synthRunning = false;
}

function createOscillator(freq, gainValue) {
    let oscillator = context.createOscillator();
    let gainNode = context.createGain();

    oscillator.frequency.setValueAtTime(freq, context.currentTime);
    gainNode.gain.value = gainValue;

    oscillator.connect(gainNode); // Connect oscillator to gain node
    return { oscillator, gainNode };
}

function createLFO(freq, lfoAmount) {
    let lfoOscillator = context.createOscillator();
    let lfoGain = context.createGain();

    lfoOscillator.frequency.setValueAtTime(freq, context.currentTime);
    lfoGain.gain.value = lfoAmount; // Start with initial LFO amount

    lfoOscillator.connect(lfoGain);
    return { oscillator: lfoOscillator, gain: lfoGain };
}

function connectLFOToOscillators(lfo, voiceOscillator) {
    lfo.gain.connect(voiceOscillator.oscillator.frequency); // Modulate frequency
}

function createFilter() {
    let filterNode = context.createBiquadFilter();
    filterNode.type = 'lowpass'; // Set filter type
    filterNode.frequency.value = 10000; // Static low cut at 8kHz
    filterNode.Q.value = 25; // Increased resonance for a more drastic effect

    return filterNode;
}

// Real-time frequency control for voice 1 and 2
document.getElementById('frequency').addEventListener('input', function() {
    let baseFrequency = parseFloat(this.value);
    if (voice1Oscillator && voice2Oscillator) {
        voice1Oscillator.oscillator.frequency.setValueAtTime(baseFrequency, context.currentTime);
        voice2Oscillator.oscillator.frequency.setValueAtTime(baseFrequency + parseFloat(document.getElementById('distance').value), context.currentTime);
    }
});

// Real-time control for distance between the two voices
document.getElementById('distance').addEventListener('input', function() {
    let distance = parseFloat(this.value);
    if (voice2Oscillator) {
        let baseFrequency = parseFloat(document.getElementById('frequency').value);
        voice2Oscillator.oscillator.frequency.setValueAtTime(baseFrequency + distance, context.currentTime);
    }
});

// Real-time LFO frequency control
document.getElementById('lfo').addEventListener('input', function() {
    if (lfoOscillator) {
        lfoOscillator.oscillator.frequency.setValueAtTime(this.value, context.currentTime);
    }
});

// Real-time LFO amount control (previously filter frequency)
document.getElementById('filterFreq').addEventListener('input', function() {
    if (lfoOscillator) {
        lfoOscillator.gain.gain.setValueAtTime(this.value, context.currentTime); // Adjust LFO modulation depth
    }
});

// Visualizer setup
function setupVisualizer() {
    analyser = context.createAnalyser();
    analyser.fftSize = 256;
    dataArray = new Uint8Array(analyser.frequencyBinCount);

    canvas = document.getElementById('visualizer');
    canvasCtx = canvas.getContext('2d');
}

// Visualize the sound data
function visualize() {
    requestAnimationFrame(visualize);

    analyser.getByteFrequencyData(dataArray);

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    // Drawing the blob
    canvasCtx.beginPath();
    let centerX = canvas.width / 2;
    let centerY = canvas.height / 2;
    let radius = 60; // Blob base radius
    let blobRadius = 40 + dataArray[0] / 8; // Dynamic change based on frequency

    for (let i = 0; i < dataArray.length; i++) {
        let angle = (i / dataArray.length) * Math.PI * 2;
        let randomShift = Math.random() * 5 - 2.5;
        let x = centerX + Math.cos(angle) * (blobRadius + randomShift);
        let y = centerY + Math.sin(angle) * (blobRadius + randomShift);
        if (i === 0) {
            canvasCtx.moveTo(x, y);
        } else {
            canvasCtx.lineTo(x, y);
        }
    }
    canvasCtx.closePath();
    canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    canvasCtx.fill();
    canvasCtx.strokeStyle = '#00ff00';
    canvasCtx.stroke();
}
